@import './variables.scss';

button.btn {
    border-radius: $btn-border-radius;
}
.btn.remove {
    color: $btn-remove;
    background-color: $btn-remove-background;
}

button:disabled, .btn:disabled {
    cursor: not-allowed !important;
    pointer-events: all;
}
