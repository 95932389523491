@import '../../styles/variables';

.crew-manager-view {
    .member-container {
        min-width: 300px;

        img {
            object-fit: cover;
            width: 70px;
            height: 100px;
        }

        div.no-picture {
            width: 70px;
            height: 100px;
            background: $secondary-dark;
        }
    }
}
