@import '../../styles/variables.scss';
@keyframes grow {
    from {transform: scale(.02);}
    to {transform: scale(.8);}
}
.filter span.count-badge {
    transform: scale(.8);
    animation-fill-mode: backwards;
    animation-name: grow;
    animation-duration: 200ms;
    animation-delay:.3s;
    animation-timing-function: cubic-bezier(0.33, 0.91, 0.47, 2);
    color: black;
    align-content: center;
    position: relative;
    top: -15px;
    right:25px;
}

.accordion {

    .accordion-item {
        background-color: transparent;
        border: 1px solid $secondary ;
        .accordion-button{

            background-color: transparent;
            color: white;
        }
        .accordion-button:not(.collapsed)::after{
            filter:  brightness(203%);
        }
        .accordion-button::after {
            filter:  brightness(403%);
        }
        .accordion-header {
            background-color: $secondary-darker;
        }
        .accordion-body {
            background-color: black;
        }
    }
}

.date-container {
    color: white;

    .date-field {
        width: 100%;
    }

    .react-datepicker__close-icon {
        padding: 0;
        &::after {
            position: relative;
            left:-10px;
            background-color: $primary;
            color: black;
            transform: scale(0.85);
        }
    }
}
