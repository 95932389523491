@import '../../styles/variables';

@mixin content-type-badge($bg-color, $padding-end) {
    .content-type {
        background-color: $bg-color;
        font-style: italic;
        font-weight: bold;
        font-size: small;
        border-radius: 0 0 4px 0;
        padding: 3px $padding-end 3px 3px;
        position: absolute;
        top: 0;
    }
}

.info {
    border-left: 1px solid $primary;
    position: relative;

    &.OV {
        color: black;
        border-left: 4px solid $lime;
        position: relative;
        @include content-type-badge($lime, 4px);
    }

    &.VF {
        color: white;
        border-left: 4px solid $purple;
        position: relative;
        @include content-type-badge($purple, 5px);
    }
}