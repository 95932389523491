input.form-control, textarea.form-control {
    height: 48px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    padding: 0 16px;
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.15px;
    line-height: 19.2px;
    font-size: 16px;
    color: $secondary;
    vertical-align: middle;
    text-align: left;
    min-width: 65px;
    outline: none;
    border-color: $secondary-dark;

    &:focus {
        outline: none;
        //border: none;
        border-color: $secondary;
        background-color: transparent;
        color: rgb(255, 255, 255);
    }
}

textarea.form-control {
    min-height: 125px;
}

input[type="checkbox"] {
    border-color: $secondary-dark;
    background-color: transparent;

    &:checked {
        border-color: $secondary-dark;
        background-color: transparent;

    }
}

label.input-text-label {
    transition: all 0.15s ease-in;
    transform: translate(10px, -40px);
    pointer-events: none;
    color: $secondary;

}

label.checkbox-label {
    color: $secondary;
}

input:focus ~ label.input-text-label, input:valid ~ label.input-text-label {
    transform: translate(5px, -60px) scale(0.9);
    color: white;
    background-image: url(../../public/broken_noise.png);
    padding:0 15px 0 10px;
}

select.form-select {
    border-radius: 0;
    height: 48px;
    background: transparent;
    color: white;
    border-color: rgba(255, 255, 255, 0.355);

    option {
        color: black;
    }
}

select.form-select-small {
    justify-self:end;
    border-radius: 0;
    height: 48px;
    width: 135px;
    padding: 0 10px;
    background: transparent;
    color: white;
    border-color: rgba(255, 255, 255, 0.355);

    option {
        color: black;
    }
}
