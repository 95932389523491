.highlight-film {
    .film-cover {
        height: 400px;
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;

        .gradient {
            height: 160px;
            background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
        }
    }

    .film-actions {
        margin-top: -160px;
        max-width: 800px;

        .film-logo {
            width: 340px;
        }

        .active-orders {
            font-size: 12px;
        }

        p.movie-synopsis {
            font-size: 16px;
        }
    }
}
