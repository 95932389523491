.search-bar-component {
    border: 1px solid #222;

    .input-group-text {
        height: 56px;
        width: 56px;
        border-radius: 0;

        span.fas {
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            padding-right: 30px;
            cursor: pointer;
        }
    }

    input.form-control {
        height: 56px;
        width:250px;
        max-width: 9999px;

        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
            opacity: 1;
        }

        color: rgba(255, 255, 255, 0.5);
        border-radius: 0;
    }
}
