@import './variables';

.modal.trailer .modal-content {
    background: black;

    .modal-header.non-editable {
        position: absolute;
        z-index: 3 !important;
        top: 0;
        right: 0;
        border-bottom: none;
    }
}

.modal .modal-content {
    background: url('../../public/broken_noise.png') repeat;
    color: #fff;
    padding: 2rem;
    border-radius: 0;
    border-top: 5px solid rgba(255, 255, 255, 0.1);
    border-bottom: 5px solid rgba(255, 255, 255, 0.1);

    .btn-close {
        filter: var(--bs-btn-close-white-filter);
    }

    .modal-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        div.modal-title {
            letter-spacing: 4px;
            font-size: 12px;
        }

        h1 {
            font-size: 32px;
        }
    }

    .modal-footer {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
}

.modal {
    .status-filter-modal {
        div.status {
            opacity: 0.5;
            transition: opacity 0.2s;

            &:hover, &.selected {
                opacity: 1;
            }
        }
    }

    .movies-filter-modal, .organizations-filter-modal, .actions-filter-modal {
        .list-group {
            .list-group-item {
                background: rgba($secondary, 0.05);
                transition: background-color 0.2s ease-in-out;

                &:hover, &.active {
                    background: rgba($secondary, 0.1);
                }
            }
        }
    }
}