@import '../../styles/variables';

.avatar {
    --font-size: calc(var(--avatar-size) * 0.4);

    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;
    background-color: $primary-dark;
    border: 2px solid $primary;

    &:hover .avatar-overlay {
        opacity: 1;
        visibility: visible;
    }

    .avatar-img {
        border-radius: 50%;
    }

    .avatar-initials {
        margin-top: 5px;
        margin-right: 3px;
        font-size: var(--font-size);
    }

    .avatar-overlay {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;
        cursor: pointer;
        font-size: 16px;

        span {
            pointer-events: none;
        }
    }
}
