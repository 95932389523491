@import '../../styles/variables';

.transactions-view {
    .credit-actions {
        .btn {
            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .transactions-list {
        .transaction-item {
            border-left-width: 4px !important;

            .ba-transaction {
                visibility: hidden;
            }

            &:hover {
                .ba-transaction {
                    visibility: visible;
                }
            }

            &.credit {
                background: rgba(var(--bs-success-rgb), 0.03);
                &:hover {
                    background: rgba(var(--bs-success-rgb), 0.1);
                }
            }
            &.debit {
                background: rgba(var(--bs-danger-rgb), 0.03);
                &:hover {
                    background: rgba(var(--bs-danger-rgb), 0.1);
                }
            }
        }
    }
}
