.movie-slider-component {
    .movie-slider {
        gap: 18px;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }
    }


    .scroll-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        border: none;
        cursor: pointer;
        color: white;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    .scroll-button-left {
        left: 0;
        background: linear-gradient(to left, transparent, black);
    }

    .scroll-button-right {
        right: 0;
        background: linear-gradient(to right, transparent, black);
    }

    @media (min-width: 992px) {
        .scroll-button {
            display: flex;
        }
    }
}
