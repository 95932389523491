.crew-card-component, .cast-card-component, .preview-container {
    background-color: rgba(255,255,255,0.1);
    color: white;
    padding: 0;
    margin-right: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    font-family: Quicksand, sans-serif;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }

    &.add {
        background: none;
        cursor: pointer;
        transition:  font 0.2s ease;
        &:hover {

            background: rgba(255,255,255,0.1);
        }
    }

    .photo {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info {
        line-height: 20px;

        .name, .role {
            font-size: 14px;
        }
    }



    &:hover .edit-overlay,
    &:hover .remove-overlay {
        opacity: 1;
    }
}

.crew-card-component, .crew-members-container .crew-member, .preview-container {
    width: 250px;
    height: 72px;

    .photo {
        width: 72px;
        height: 72px;
    }

    .edit-overlay, .remove-overlay {
        cursor: pointer;
        top: 0;
        opacity: 0;
        transition: opacity, font 0.2s ease;

        span {
            color: black;
            text-align: center;
            pointer-events: auto;
        }
    }

    .edit-overlay, .remove-overlay {
        height: 72px;
        pointer-events: auto;
    }

    .edit-overlay {
        background-color: rgba(249, 210, 125, 0.65);
        left: 0;
        right: 72px;
        &:hover{
            font-size: large;
            background-color:  rgba(249, 210, 125, 0.85);
        }
    }

    .remove-overlay {
        right: 0;
        width: 72px;
        background-color: rgba(220, 53, 69, 0.65);
        &:hover{
            font-size: large;
            background-color: rgba(220, 53, 69, 0.85);
        }
    }
}

.cast-card-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 128px;
    height: 227px;
    background-color: rgba(255,255,255,0.1);
    color: white;
    padding-bottom: 8px;
    box-sizing: border-box;
    margin-right: 16px;
    margin-bottom: 16px;
    font-family: Quicksand, sans-serif;
    overflow: hidden;

    .photo {
        width: 128px;
        height: 160px;
        padding-bottom: 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info {
        text-align: left !important;
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .role-name {
            font-size: 12px;
            max-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;

        }
        .name{
            font-size: 14px;
        }


    }



    .edit-overlay, .remove-overlay {
        cursor: pointer;
        top: 0;
        opacity: 0;
        transition: opacity, font 0.2s ease;

        span {
            color: black;
            text-align: center;
            pointer-events: auto;
        }
    }

    .edit-overlay, .remove-overlay {
        height: 152px;
        pointer-events: auto;


    }

    .edit-overlay {
        background-color: rgba(249, 210, 125, 0.65);
        left: 0;
        right: 42px;
        &:hover{
            font-size: large;
            background-color: rgba(249, 210, 125, 0.85);
        }

    }

    .remove-overlay {
        right: 0;
        width: 42px;
        background-color: rgba(220, 53, 69, 0.65);
        &:hover{
            font-size: large;
            background-color: rgba(220, 53, 69, 0.85);
        }
    }

    &.add {
        text-align: center;
        transition:  font 0.2s ease;
    }

    span.add-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 48px;
    }
}

.crew-members-container {
    .crew-member, .cast-member {
        background-color: rgba(255,255,255,0.1);
        flex: 0 0 31%;
        cursor: pointer;

        img {
            width: 72px;
            height: 72px;
            object-fit: cover;
        }

        &:hover {
            background-color: rgba(255,255,255,0.3);
        }

        &.selected {
            background-color: rgba(249, 210, 125,0.3);
        }

        .selected {
            right: 10px;
            top: 10px;
        }
    }
}

.role-selector {
    gap: 2px;
    span.fa-check-circle {
        right: 5px;
        top: 10px;
    }
}
