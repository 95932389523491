@import '../../styles/variables.scss';

.notifications-page-view {
    .notifications-card {
        min-height: 85px;
        padding-left: 35px;
        cursor: pointer;
        opacity: 0.75;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }

        span.fas.bg-broken {
            width: 30px;
            height: 30px;
            left: -16px;
            padding-top: 3px;
            padding-bottom: 4px;
            border-radius: 50%;
        }
    }
}
