@import '../../styles/variables.scss';

.movie-card-component {
    background-color: $secondary-darker;
    border-left: 1px solid $primary-dark;

    .logo-img {
        border: 1px solid $primary;
        margin-left: 15px;
    }

    .status {
        white-space: nowrap;
        font-size: small;
        background-color: $secondary-darker;
        width: fit-content;
        justify-self: end;
        max-height: 29px;
    }

}