@import '../../styles/variables.scss';

@keyframes shrink {
    from {top:-100px;}
    to {top: -16px;}
}
.dummy{
    min-height:200px;
}
.header {
    &.shrink{
        background-color: rgb(19, 19, 19);
        position: sticky;
        top: -16px;
        display: flex;
        min-height: auto;
        padding:10px;
        animation: shrink;
        animation-fill-mode: backwards;
        animation-duration: 250ms;
        z-index:3;

        .title {
            flex-grow: 1;
            justify-content: left;
            h1{
                padding-top: 10px;
                width: auto;
            }
        }

        .search-bar-component{
            border:none;

            .input-group{
                display: grid;
                grid-template-columns: 1fr 1fr ;
                max-width: 300px;
            }

            input.form-control {
                transition: max-width 750ms 1.3s cubic-bezier(0, 0.77, 0.42, 0.74);//
                max-width: 30px;
                position: relative;
                left:10px;

                &:focus{
                    transition: max-width 100ms cubic-bezier(0, 0.77, 0.42, 0.74);//
                    max-width:250px;
                }

                &::placeholder {
                    color: rgba(255, 255, 255, 0.5);
                }

                color: rgba(255, 255, 255, 0.5);
                border-radius: 0;
            }
            .input-group-text {
                height: 56px;
                width: 45px;
                border-radius: 0;

                span.fas {
                    width: auto;
                }
            }
        }
        .toggleButtons {

            max-height: 28px;
        }
    }
    }