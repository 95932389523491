.timezone-select__control {
    background-color: transparent !important;
    border: 1px solid #555 !important;
    color: #fff !important;
    border-radius: 0 !important;
    height: 48px;
    min-height: 48px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        border-color: #666 !important;
    }
}

.timezone-select__input {
    color: #fff !important;
}

.timezone-select__single-value {
    cursor: pointer;
    color: #fff !important;
}

.timezone-select__menu {
    cursor: pointer;
    background-color: #444 !important;
    color: #fff !important;
    border-radius: 0;
}

.timezone-select__option {
    cursor: pointer;
    background-color: #444 !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &--is-focused,
    &--is-selected {
        cursor: pointer;
        background-color: #555 !important;
        color: #fff !important;
    }
}
