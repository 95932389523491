.distributors-page-view {
    .distributor-header {
    }

    .is-edit {
        position: relative;
        cursor: pointer;

        &::after {
            content: '\f303';
            font-family: 'Font Awesome 6 Free', sans-serif;
            font-weight: 900;
            opacity: 0;
            visibility: hidden;
            margin-left: 5px;
            transition: opacity 0.2s, visibility 0.2s;
        }

        &:hover {
            text-decoration: underline;

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .countries-default {
        border-radius: 0;

        .list-group-item {
            border: 0;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;

            &:hover, &.expanded {
                opacity: 1;
            }

            .header {
                cursor: pointer;
            }
        }
    }
}
