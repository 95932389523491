@import '../../styles/variables';

.country-select-modal {
    .list-group {
        background: none;
        margin-top: 10px;

        .list-group-item {
            border-color: $primary-dark;

            span.fa-check-circle {
                right: 10px;
                top: 12px;
            }

            &:hover {
                background: $primary-dark;
            }
        }

        .list-group-item-primary {
            background: $primary-dark;
            color: black;
        }

        .list-group-item-secondary {
            border-radius: 0;
            color: white;
            background: none;
        }
    }
}
