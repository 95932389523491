@import "../../styles/variables";

div.sidebar {
    width: $sidemenu-width;
    height: 100vh;
    overflow-y: hidden;
    position: fixed;
    background: url(../../../public/broken_noise.png) repeat;
    border-right: 1px #222 solid;
    z-index: 10;


    @media (max-width: 767.98px) {
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 1000;
    }

    .profile-picture {
        width: 80px;
        height: 80px;
    }

    .user-name {
        letter-spacing: 3px;
    }

    .notifications {
        top: 20px;
        right: 20px;
        span.fa-bell {
            border-radius: 50%;
            font-size: 16px;
            --fa-animation-duration: 2s;
        }

        span.unread {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: -7px;
            right: -7px;
        }
    }

    @media (max-height: 845px) {
        .sidebar-footer {
            padding-top: 20px;
            background:
                    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 75%) 30%); /* Background image */
            background-size: cover; /* Ensures both layers fill the container */
            background-position: top; /* Aligns layers at the top */
            background-repeat: no-repeat, repeat; /* Prevents gradient repetition */
            background-blend-mode: multiply; /* Blends the gradient with the image */
        }
    }

    .sidebar-footer {
        width: $sidemenu-width;

        .version {
            color: rgba(255,255,255,0.5);
            letter-spacing: 1.5px;
            font-size: 12px;
        }

        button.logout {
            font-size: 14px;
        }
    }

    ul.nav {
        li.nav-item {
            border-left: 4px solid rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.07);
            padding: 7px 0;
            margin-bottom: 16px;
            opacity: 0.7;
            transition: opacity ease-in-out 200ms;

            &:hover {
                border-left-color: rgba($primary, 0.4);
                opacity: 1
            }

            &.active {
                border-left-color: $primary;
                opacity: 1
            }
        }
        .icons-off{
            color: $secondary-dark;
        }
        .icons-on{
            color: $success-green;
        }
    }
}
