@import '../../styles/variables.scss';

.organization-view {
    .header {
        transition: all 0.4s;
        margin: 24px 0 24px 0;
        padding: 16px;

        .profile-pic {
            border: 1px solid $primary;
            border-radius: 100%;
            height: auto;
            max-width: 100%;
            transition: all 0.4s;
        }
        .tier {
            background-color: $secondary-darker;
            width: fit-content;
            white-space: nowrap;
        }
        .btn {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .sticky {
        border-left: none;
        margin-left: -16px;
        margin-right: -16px;
        position: sticky;
        top: 0;
        z-index: 10;
        background-image: linear-gradient(black 85%, transparent);

        .header-content {
            margin-left: -24px;
        }
        .profile-pic {
            border: 1px solid $primary;
            border-radius: 100%;
            height: auto;
            position: relative;
            left: -16px;
            top: -8px;
            max-width: 100%;
            padding-right: 0;
            transform: scale(0.75);
            transition: all 0.4s;
        }
        .tier {
            margin-top: 0;
            font-size: small;
            background-color: $secondary-darker;
            width: fit-content;
            white-space: nowrap;
            transition: all 0.4s;
        }
        .btn {
            font-size: smaller;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .details {
        .details-card {
            min-height: 85px;
            padding-left: 35px;
            cursor: pointer;
            opacity: 0.75;
            transition: opacity 0.2s ease-in-out;

            .edit-icon {
                display: none;
            }

            &:hover {
                opacity: 1;

                .edit-icon {
                    display: inline-block;
                }
            }

            span.fas.bg-broken {
                width: 30px;
                height: 30px;
                top: 13px;
                left: -16px;
                padding-top: 3px;
                padding-bottom: 4px;
                border: 1px solid $primary;
                border-radius: 50%;
            }
        }
    }
}
