@import './styles/variables.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap/scss/bootstrap';
@import '~react-datepicker/src/stylesheets/datepicker';
@import '~react-toastify/scss/main';
@import './styles/buttons.scss';
@import './styles/fonts.scss';
@import './styles/modal.scss';
@import './styles/input.scss';
@import "./styles/highlight-film";

body {
    margin: 0;
    font-family: 'Quicksand', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #181b21;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
    outline: none !important;
}

h1 {
    color: $primary;
    font-size: 32px;
}

div.main-content {
    &:not(.bg-black) {
        background: rgba(0, 0, 0, 0.3);
    }
}

.bg-broken {
    background: url(../public/broken_noise.png) repeat;
}

.text-expand {
    letter-spacing: 3px;
}

.w-fit-content {
    width: fit-content;
}

.react-datepicker__input-container {
    height: 48px;
}

.react-datepicker__calendar-icon {
    top: 7px;
    left: 5px;
    path {
        fill: $primary;
    }
}
.react-datepicker__view-calendar-icon input {
    height: 48px;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    background: transparent;
    color: white;
    padding: 6px 10px 5px 40px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.pulse-effect {
    display: inline-block;
    animation: pulse 2s infinite;
}

.pointer {
    cursor: pointer;
}

.distributor-select {
    cursor: pointer;

    &:hover {
        h5 {
            text-decoration: underline;

            span {
                text-decoration: none;
            }
        }

    }
}

.distributor-select-modal {
    .distributor-row {
        cursor: pointer;
        background: rgba(249, 210, 125, 0.03);

        &:hover {
            background: $primary-dark;
        }
    }
}

.striped-animated {
    background-image: linear-gradient(
                    45deg,
                    rgba(0, 0, 0, 0.15) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(0, 0, 0, 0.15) 50%,
                    rgba(0, 0, 0, 0.15) 75%,
                    transparent 75%,
                    transparent
    );
    background-size: 40px 40px;
    background-repeat: repeat;
    animation: playingAnimation 1200ms linear infinite;
}

@keyframes playingAnimation {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 40px 0;
    }
}
