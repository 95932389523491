.defaults-table-component {
    .table {
        .btn {
            height: calc(1.5em + 0.5rem + 2px); // Ensures button height matches Input height
        }

        input, .form-control {
            width: 100%;
            height: 34px;
        }

        th, td {
            vertical-align: middle; // Centers content vertically in table cells
        }

        .fas.fa-trash {
            color: #dc3545; // Color for the trash icon
        }
    }
}
