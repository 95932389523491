.login-view {
    background: url(../../../public/broken_noise.png) repeat;

    .form-wrap {
        img {
            padding-bottom: 20px;
        }

        .submit-wrap {
            padding-top: 30px;
        }
    }
}
