@import '../../styles/variables';

.list-group.version-list-container {
    border-radius: 0;

    .list-group-item {
        border: 0;
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;

        &:hover, &.expanded {
            opacity: 1;
        }

        .header {
            cursor: pointer;
        }

        .react-datepicker__close-icon::after{
            background-color: transparent;
            font-size: larger;

        }
    }
}
