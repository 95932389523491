@import '../../styles/variables';

@mixin list-group-playlists() {
    .list-group {
        .list-group-item {
            background: rgba($secondary, 0.05);
            transition: background-color 0.2s ease-in-out;

            &:hover, &.active {
                background: rgba($secondary, 0.1);
            }
        }
    }
}

.playlist-index-view {
    @include list-group-playlists();
}

.modal-dialog.playlist-select-version {
    @include list-group-playlists();
}
