@import '../../styles/variables.scss';

.movie-card {
    height: 237px;
    max-width: 162px;
    border-radius: 0;
    border-bottom: 1px solid #f9d27d;
    background: transparent;
    img {
        height: 233px;
    }
    .card-footer {
        border-top: 1px solid #f9d27d;
        border-radius: 0;
        font-size: 12px;
        text-transform: uppercase;
        white-space: nowrap;
        height: 35px;
        background-color: $secondary-darker;
        justify-content: center;
        align-items: center;
    }

    .card-link {
        text-decoration: none;
    }
}

.movie-card.animatedFooter {
    overflow: hidden;
    transition: all 190ms;

    :hover{
        +.card-footer {
            transition: all 190ms;
            margin-top: -75px;
            height: 110px;
            background: #242424;
            overflow:auto;
            .hover-info {
                transition: all 190ms;
                display: block;
            }
            .ellipsis{
                display: none;
            }
        }
    }

    .card-footer {
        border-top: 1px solid #f9d27d;
        border-radius: 0;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        background: #242424;
        white-space: nowrap;
        height: 35px;
        overflow: hidden;
        transition: all 190ms;
        scrollbar-width: none;
        -ms-overflow-style: none;
        .hover-info {
            display: none;
        }
        .ellipsis{
            transform: scale(1.2) ;
        }

        ::-webkit-scrollbar {
            display: none;
        }

        &:hover{
            transition: all 190ms;
            margin-top: -75px;
            height: 110px;
            background: #242424;
            overflow:auto;
            .hover-info {
                transition: all 190ms;
                display: block;
                white-space: nowrap;
            }
            .ellipsis{
                display: none;
            }
        }
    }

    .card-link {
        text-decoration: none;
    }
}

.trailer-card-actions {
    div.pointer {
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
}