.movie-slider-component {
    .movie-slider {
        gap: 18px;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
