@import '../../styles/variables.scss';

.status {
    color: $primary;
    background-color: $secondary-darker;
    width: fit-content;

    &.success{
        border-left: 2px solid $success-green;
        background-image: linear-gradient( to right, $success-green, transparent 12%);
    }
    &.danger{
        border-left: 2px solid $danger-red;
        background-image: linear-gradient( to right, $danger-red, transparent 12%);
    }
    &.warning{
        border-left: 2px solid $warning-orange;
        background-image: linear-gradient( to right, $warning-orange, transparent 12%);

    }

    .marquee {
        width: 123px; /* Width of the visible container */
        overflow: hidden; /* Hide overflowing text */
        white-space: nowrap;
        box-sizing: border-box;
        position: relative;

        span {
            display: inline-block;
            position: relative;
            animation: scroll 5s linear infinite; /* Continuous loop */
        }

        @keyframes scroll {
            0% {
                transform: translateX(123px); /* Start completely outside to the right */
            }
            100% {
                transform: translateX(-100%); /* End completely outside to the left */
            }
        }
    }

}