$primary: #f9d27d;
$primary-dark: rgba(249, 210, 125, 0.5);
$blue:rgba(0, 191, 255, 0.871);
$lime: rgb(179, 255, 0);
$purple: rgb(128, 0, 128);

$secondary: rgba(255, 255, 255, 0.5);
$secondary-dark: rgba(255, 255, 255, 0.355);
$secondary-darker: rgba(255, 255, 255, 0.07);

$btn-border-radius: 0;
$btn-remove:rgb(219, 106, 103);
$btn-remove-background:rgb(21, 21, 21);

$input-focus-box-shadow: none;
$input-placeholder-color: rgba(255, 255, 255, 0.5);

$white-text: rgba(255, 255, 255, 0.9);

$success-green: rgb(79, 158, 113);
$danger-red: rgba(220, 53, 69, 1);
$warning-orange: rgb(255, 172, 7);

$sidemenu-width: 310px;
