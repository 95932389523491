@import '../../styles/variables.scss';

.user-view {
    .header {
        border-left: 1px solid $secondary;

        .profile-pic {
            border: 1px solid $primary;
            border-radius: 100%;
            height: auto;
            max-width: 100%;

        }
        .role, .email {
            background-color: $secondary-darker;
            width: fit-content;
            white-space: nowrap;
        }
    }

    .details {
        .details-card {
            min-height: 85px;
            padding-left: 35px;
            cursor: pointer;
            opacity: 0.75;
            transition: opacity 0.2s ease-in-out;

            .edit-icon {
                display: none;
            }

            &:hover {
                opacity: 1;

                .edit-icon {
                    display: inline-block;
                }
            }

            span.fas.bg-broken {
                width: 30px;
                height: 30px;
                top: 13px;
                left: -16px;
                padding-top: 3px;
                padding-bottom: 4px;
                border: 1px solid $primary;
                border-radius: 50%;
            }
        }
    }
}
