@import '../../styles/variables';

$playerHeight: 160px;

div.layout-parent {
    .player {
        z-index: 1000;
        pointer-events: none;

        .player-container {
            pointer-events: all;
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - $sidemenu-width);
            height: $playerHeight;
            background: linear-gradient(to bottom, transparent, #332913 30%);
        }

        @media (max-width: 1199.98px) {
            .player-container {
                width: 100%;
            }
        }
    }

    &.impersonating {
        .sidebar-footer {
            bottom: 50px !important;
        }

        .main-content {
            margin-bottom: 50px;
        }

        .impersonating-container {
            border: 5px solid rgba(255, 0, 0, 0.7);
            z-index: 1000;
            pointer-events: none;

            .toolbar {
                pointer-events: all;
                background: rgba(255, 0, 0, 0.7);
                box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.3);
                height: 50px;
            }
        }
    }

    div.main-content {
        margin-left: $sidemenu-width;
        flex-grow: 1;
        overflow-y: auto;

        @media (max-width: 1199.98px) {
            flex-grow: 1;
            margin-left: 0;
            margin-top: 56px;
        }

        &.playing {
            margin-bottom: 75px;
        }
    }
}
