@import '../../styles/variables.scss';

.organization-card-component {
    background-color: $secondary-darker;
    border-left: 1px solid $primary-dark;

    .logo-img {
        border: 1px solid $primary;
        margin-left: 15px;
    }
    .icons-off{
        color: $secondary-dark;
    }
    .icons-on{
        color: $success-green;
    }
}
