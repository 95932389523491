@import '../../styles/variables';

.toggle-switch {
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;

    &.on {
        background-color: $primary;
    }

    &.off {
        background-color: #ccc;
    }

    .toggle-knob {
        width: 28px;
        height: 28px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: transform 0.3s;
    }

    &.on .toggle-knob {
        transform: translateX(29.9px);
    }

    &.off .toggle-knob {
        transform: translateX(0);
    }
}
