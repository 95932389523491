@import '../../styles/variables.scss';

.order-card-component {
    background-color: $secondary-darker;

    .logo-img {
        border: 1px solid $primary;
        margin-left: 15px;
    }

    .organization {
        border-left: 1px solid $secondary;
        font-size: small;
        text-transform: uppercase;
    }
    .user {
        font-size:small;
        border-left: 2px solid $primary;
    }
}
