@import '../../styles/variables.scss';

.movie-detail-view {
    &.can-edit {
        div.film-cover {
            transition: background-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-blend-mode: darken;
                background-color: rgba(0,0,0,0.3);

                .update-still-info {
                    opacity: 1;
                }
            }

            .update-still-info {
                opacity: 0;
                transition: opacity 0.2s ease;

                p.lead {
                    border: 3px dashed white;
                }
            }
        }


        div.film-logo {
            transition: border 0.2s ease;
            border: 3px dashed transparent;
            cursor: pointer;

            &:hover {
                transition: background-color 0.2s ease;
                border: 3px dashed white;
                background-color: rgba(255,255,255,0.2);
            }

            img {
                max-height: 160px;
            }
        }

        h1.movie-title, p.movie-synopsis, div.icons {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        .add-genres, .add-content-restrictions {
            cursor: pointer;
            opacity: 0.5;
            transition: opacity 0.2s ease;
            &:hover {
                text-decoration: underline;
                color: $primary;
                opacity: 1;
            }
        }
    }
}
