@font-face {
    font-family: 'Quicksand';
    src: url('./fonts/Quicksand.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./fonts/Quicksand_Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./fonts/Quicksand_Bold_Oblique.otf') format('opentype');
    font-weight: bold;
    font-style: oblique;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./fonts/Quicksand_Oblique.otf') format('opentype');
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: 'Space Mono';
    src: url('./fonts/SpaceMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
