@import '../../styles/variables.scss';
.conditions {
    border-left:  2px solid $primary;
    background: rgba($secondary, 0.03);
    @media (max-width: 992px) {
        border: 0;
    }

    .condition {
        grid-template-columns: 1fr 4fr 1fr;
        row-gap: 10px;
        .date-field {
            min-width: 300px;
        }

        .btn-close {
            filter: var(--bs-btn-close-white-filter);
        }
    }

    .btn.add {
        background-color: $secondary-darker;
        color: white;
        border-color: $secondary;
    }
}

