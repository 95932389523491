@import '../../styles/variables.scss';

.tier-badge {
    border-radius: 5px;

    &.premium {
        background-color: rgba(255, 228, 196, 0.298);

    }
    &.mid {
        background-color: $secondary-dark ;
        color: white;
    }
}
