@import '../../styles/variables';

.pincode-container {
    .pin-inputs {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;

        &.shake {
            animation: shake 0.3s ease-in-out;
        }

        .pin-square {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            border: 2px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            margin: 0 5px;
        }
    }

    .numeric-keyboard {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        width: 100%;
        max-width: 300px;

        .key {
            padding: 20px 0;
            border: none;
            background-color: transparent;
            color: $primary;
            font-size: 20px;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;

            &:hover, &:active {
                background-color: $primary;
                color: black;
            }
        }

        .ok {
            background-color: $primary;
            color: black;
            &:hover, &:active {
                color: black;
            }
        }
    }
}

@media (min-width: 768px) {
    .pincode-container {
        width: 400px;
    }
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
}
